import React from "react";
import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import { formatDate } from "utils/format";
import { usePosts } from "../api/getPosts";

export const LatestNews = () => {
  const postsQuery = usePosts();

  if (postsQuery.isLoading) {
    return <></>;
  }

  if (!postsQuery.data) return null;

  return (
    <div className="flex flex-col mb-1 pt-2">
      <div className="text-blue-800 px-4 font-extrabold tracking-tight sm:text-xl mb-2">
        <span className="inline-block flex flex-row justify-between text-sm uppercase items-center">
          <span>Latest News</span>
          <Link
            to="/news"
            className="text-blue-800 text-xs flex flex-row space-x-2 font-bold hover:underline hover:text-red-500"
          >
            <span>View archives</span>
            <ArrowRight className="h-4 w-4 inline-block" />
          </Link>
        </span>
        {/* <div className="h-2 w-40 bg-blue-800 rounded-b-md mt-2"></div> */}
      </div>
      <div>
        <div>
          <div className="grid grid-cols-1">
            {postsQuery.data
              .filter((post) => !post.draft)
              .slice(0, 3)
              .map((post, index) => (
                <Link
                  key={index}
                  to={`/news/${post.slug}`}
                  className="my-1 text-blue-800"
                >
                  <div className="card bg-white overflow-hidden rounded-xl p-2 space-y-4 hover:bg-gray-100 hover:border hover:shadow-sm cursor-pointer">
                    <div className="space-y-1">
                      <h2 className="text-blue-900 uppercase text-md md:text-md">
                        {post.title}
                      </h2>
                      <p className="text-sm select-none">{post.excerpt}</p>
                      <p className="p-2 text-xs text-red-400 font-medium leading-3 flex justify-between">
                        <span>{formatDate(post.createdAt)}</span>
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
