import React from "react";
import { useEffect, useState } from "react";
import { MainLayout } from "components/Layout";
import { Head } from "components/Head";
import { axios } from "lib/axios";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { UPLOADS_URL } from "config";

export const Search = () => {
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get("q");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post("/search", { q: searchQuery });
        setSearchResults(response);
      } catch (error) {
        setError("An error occurred while searching.");
      } finally {
        setIsLoading(false);
      }
    };

    console.log(searchResults);

    if (searchQuery) {
      fetchData();
    }
  }, [searchQuery]);

  return (
    <MainLayout title="Search">
      <Head title="Search" description="Search results" />
      <div className="mx-4 md:mx-32 my-8">
        <h1 className="text-4xl font-bold">Search Results...</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mx-4 gap-4 md:mx-32 my-10">
        <div className="w-full">
          <div className="p-4">
            {isLoading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
            {searchResults.posts?.length > 0 ? (
              <ul className="space-y-4">
                {searchResults.posts?.map((result) => (
                  <li key={result.slug}>
                    <Link
                      to={`/news/${result.slug}`}
                      className="text-blue-500 hover:underline"
                    >
                      {result.title}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
            {searchResults.circulars?.length > 0 ? (
              <ul className="space-y-4">
                {searchResults.circulars?.map((result) => (
                  <li key={result._id}>
                    <a
                      target="_blank"
                      href={UPLOADS_URL + result.location}
                      className="text-blue-500 hover:underline"
                    >
                      {result.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
            {searchResults.summarySheets?.length > 0 ? (
              <ul className="space-y-4">
                {searchResults.summarySheets?.map((result) => (
                  <li key={result._id}>
                    <a
                      target="_blank"
                      href={UPLOADS_URL + result.location}
                      className="text-blue-500 hover:underline"
                    >
                      {result.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
            {searchResults.otherDocuments?.length > 0 ? (
              <ul className="space-y-4">
                {searchResults.otherDocuments?.map((result) => (
                  <li key={result._id}>
                    <a
                      target="_blank"
                      href={UPLOADS_URL + result.location}
                      className="text-blue-500 hover:underline"
                    >
                      {result.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
