import { Head } from "components/Head";
import { MainLayout } from "components/Layout";
import {
  ArrowRightCircle,
  ChevronRight,
  Clock,
  Home,
  MapPin,
  Phone,
  Send,
} from "react-feather";
import { Link } from "react-router-dom";
import { Button, Card } from "components/Elements";
import {
  DocumentUploadField,
  Form,
  InputField,
  RegularTextAreaField,
  SelectField,
} from "components/Form";
import Swal from "sweetalert2";
import { useState } from "react";
import { useCreateAlumni } from "../api/createAlumni";
import { useEffect } from "react";

export const BecomeAnAlumni = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [avatar, setAvatar] = useState(null);

  const createAlumniMutation = useCreateAlumni();

  if (createAlumniMutation.isLoading) return <></>;

  return (
    <MainLayout title="UBTEB Alumni" className="bg-gray-100">
      <Head description="UBTEB Alumni" />
      <div className="bg-[url('assets/images/backgrounds/mandate.png')]  bg-opacity-25 bg-auto bg-center">
        <div className="px-12 md:px-24 uppercase py-20 text-white font-black text-2xl bg-black bg-opacity-50">
          <span>UBTEB Alumni</span>
          <nav class="flex mt-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
              <li class="inline-flex items-center">
                <Link
                  to="/"
                  class="inline-flex items-center text-sm font-medium text-gray-400 hover:text-white dark:text-gray-400 dark:hover:text-white"
                >
                  <Home className="w-4 h-4 mr-2" />
                  Home
                </Link>
              </li>
              <li>
                <div class="flex items-center">
                  <ChevronRight className="w-4 h-4 mr-2" />
                  <Link
                    href="/alumni-registration"
                    class="inline-flex items-center text-sm font-medium text-gray-400 hover:text-white dark:text-gray-400 dark:hover:text-white"
                  >
                    UBTEB Alumni
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="mx-10 md:mx-24 my-10">
        <div className="my-4">
          <span className="text-lg font-bold">Register as UBTEB Alumni</span>
          <br />
          <span className="text-sm"></span>
        </div>
        <div className="border border-blue-800 rounded-md shadow bg-white p-10">
          <Form
            id="alumni-registration"
            onSubmit={async (values) => {
              const {
                name,
                email,
                phone,
                course,
                institution,
                year_of_completion,
                current_place_of_work,
                current_job_title,
                current_qualifications,
              } = values;
              const bodyFormData = new FormData();
              bodyFormData.append("avatar", avatar);
              bodyFormData.append("name", name);
              bodyFormData.append("email", email);
              bodyFormData.append("phone", phone);
              bodyFormData.append("course", course);
              bodyFormData.append("institution", institution);
              bodyFormData.append("year_of_completion", year_of_completion);
              bodyFormData.append(
                "current_place_of_work",
                current_place_of_work
              );
              bodyFormData.append("current_job_title", current_job_title);
              bodyFormData.append(
                "current_qualifications",
                current_qualifications
              );
              await createAlumniMutation.mutateAsync({
                data: bodyFormData,
              });
              setAvatar(null);
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Thank you for your submission. We will get back to you within 2 working days.",
                showConfirmButton: false,
                timer: 1500,
              });
            }}
          >
            {({ register, formState }) => (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputField
                    label="Name"
                    error={formState.errors["name"]}
                    registration={register("name")}
                  />
                  <InputField
                    label="Phone"
                    type="tel"
                    error={formState.errors["phone"]}
                    registration={register("phone")}
                  />
                  <InputField
                    label="Email"
                    type="email"
                    error={formState.errors["email"]}
                    registration={register("email")}
                  />
                  <InputField
                    label="Institution"
                    error={formState.errors["institution"]}
                    registration={register("institution")}
                  />
                  <InputField
                    label="Course"
                    error={formState.errors["course"]}
                    registration={register("course")}
                  />
                  <InputField
                    label="Year of Completion"
                    error={formState.errors["year_of_completion"]}
                    registration={register("year_of_completion")}
                  />
                  <InputField
                    label="Current place of work"
                    error={formState.errors["current_place_of_work"]}
                    registration={register("current_place_of_work")}
                  />
                  <InputField
                    label="Current Job Title"
                    error={formState.errors["current_job_title"]}
                    registration={register("current_job_title")}
                  />
                  <div className="col-span-2">
                    <RegularTextAreaField
                      label="Current Qualifications"
                      error={formState.errors["current_qualifications"]}
                      registration={register("current_qualifications")}
                    />
                  </div>
                </div>
                <div className="mx-auto">
                  <InputField
                    label="Photo of Alumni"
                    type="file"
                    error={formState.errors["avatar"]}
                    onChange={(e) => {
                      // set maximum size of file to 10 MB, only allow jpg and png and image size must be 500 x 500
                      if (e.target.files[0].size > 10000000) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "File size is too large!",
                        });
                        return;
                      }
                      if (
                        e.target.files[0].type !== "image/jpeg" &&
                        e.target.files[0].type !== "image/png"
                      ) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "File type is not supported!",
                        });
                        return;
                      }
                      setAvatar(e.target.files[0]);
                    }}
                  />
                </div>
              </>
            )}
          </Form>
          <Button
            className="mt-4"
            form="alumni-registration"
            type="submit"
            size="sm"
            isLoading={createAlumniMutation.isLoading}
          >
            Submit Form
          </Button>
        </div>
      </div>
    </MainLayout>
  );
};
