import { Head } from "components/Head";
import { Breadcrumbs, MainLayout } from "components/Layout";
import { useEffect } from "react";
import newsBgImg from "assets/images/banner/skills.jpeg";

export const ReplaceLostDocuments = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const routes = [
    {
      name: "Replace Lost Academic Documents",
      path: "/replace-lost-academic-documents",
    },
  ];

  return (
    <MainLayout title="Replace Lost Academic Documents">
      <Head title="Replace Lost Academic Documents" />
      <div class="relative overflow-hidden h-80">
        <img
          src={newsBgImg}
          alt="Replace Lost Academic Documents"
          class="object-cover w-full h-full"
        />
        <div class="absolute w-full py-10 bottom-0 inset-x-0  text-white px-24 text-xs leading-4 bg-gradient-to-t from-black">
          <h1 className="text-6xl font-bold">
            Replace Lost Academic Documents
          </h1>
          <Breadcrumbs routes={routes} />
        </div>
      </div>
      <div className="mx-20 my-20"></div>
    </MainLayout>
  );
};
