import { Head } from "components/Head";
import { MainLayout } from "components/Layout";
import {
  ArrowRightCircle,
  ChevronRight,
  Clock,
  Home,
  MapPin,
  Phone,
  Send,
} from "react-feather";
import { Link } from "react-router-dom";
import { Button, Card } from "components/Elements";
import { DocumentUploadField, Form, InputField } from "components/Form";
import Swal from "sweetalert2";
import { useState } from "react";
import { useReportMalpractice } from "../api/reportMalpractice";
import { useEffect } from "react";

export const ReportMalpractice = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [location, setLocation] = useState("");
  const [signature, setSignature] = useState("");

  const reportMalpracticeMutation = useReportMalpractice();

  if (reportMalpracticeMutation.isLoading) return <></>;

  return (
    <MainLayout title="Report Malpractice" className="bg-gray-100">
      <Head description="Report Malpractice" />
      <div className="bg-[url('assets/images/backgrounds/mandate.png')]  bg-opacity-25 bg-auto bg-center">
        <div className="px-12 md:px-24 uppercase py-20 text-white font-black text-2xl bg-black bg-opacity-50">
          <span>Report Malpractice</span>
          <nav class="flex mt-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
              <li class="inline-flex items-center">
                <Link
                  to="/"
                  class="inline-flex items-center text-sm font-medium text-gray-400 hover:text-white dark:text-gray-400 dark:hover:text-white"
                >
                  <Home className="w-4 h-4 mr-2" />
                  Home
                </Link>
              </li>
              <li>
                <div class="flex items-center">
                  <ChevronRight className="w-4 h-4 mr-2" />
                  <Link
                    href="/report-malpractice"
                    class="inline-flex items-center text-sm font-medium text-gray-400 hover:text-white dark:text-gray-400 dark:hover:text-white"
                  >
                    Report Malpractice
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="mx-10 md:mx-24 my-10">
        <div className="bg-white rounded-md p-8 shadow">
          <p className="mb-3">
            Examination malpractice according to World Bank (2001) is defined as
            “a deliberate wrong doing that is contrary to official examination
            rules and is purposed to place a candidate at an unfair advantage or
            disadvantage”. “Examination malpractice” means any act, default or
            practice which is in breach of the rules or which compromises,
            attempts to compromise or may compromise the process of assessment,
            the integrity of the board examinations and the validity of the
            certificates awarded”, (UBTEB, 2015, pg. 46).
          </p>
          <p>
            The Board has been able to combat Examinations malpractices to a
            very big percentage. Here are some of the strategies that students
            use in Examinations Malpractice.
          </p>
        </div>

        <div className="my-4">
          <span className="text-lg font-bold">Report Malpractice</span>
          <br />
          <span className="text-sm"></span>
        </div>
        <div className="border border-blue-800 rounded-md shadow bg-white p-10">
          <Form
            id="report-malpractice"
            onSubmit={async (values) => {
              const {
                name_of_candidate,
                registration_number,
                date,
                name_of_examination_paper,
                year_of_study,
                nature_of_malpractice,
                name_of_supervisor,
                name_of_principal,
              } = values;
              const bodyFormData = new FormData();
              bodyFormData.append("evidence_of_malpractice", location);
              bodyFormData.append("name_of_candidate", name_of_candidate);
              bodyFormData.append("registration_number", registration_number);
              bodyFormData.append("date", date);
              bodyFormData.append(
                "name_of_examination_paper",
                name_of_examination_paper
              );
              bodyFormData.append("year_of_study", year_of_study);
              bodyFormData.append(
                "nature_of_malpractice",
                nature_of_malpractice
              );
              bodyFormData.append("name_of_supervisor", name_of_supervisor);
              bodyFormData.append("name_of_principal", name_of_principal);
              bodyFormData.append("scanned_signature", signature);
              await reportMalpracticeMutation.mutateAsync({
                data: bodyFormData,
              });
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Thank you for reporting this malpractice. Investigation will be done as soon as possible.",
                showConfirmButton: false,
                timer: 1500,
              });
            }}
          >
            {({ register, formState }) => (
              <div className="flex flex-col md:grid md:grid-cols-2 space-y-2 space-x-2">
              
                  <InputField
                    label="Name of Candidate"
                    error={formState.errors["name_of_candidate"]}
                    registration={register("name_of_candidate")}
                  />
                <InputField
                  label="Registration Number"
                  error={formState.errors["registration_number"]}
                  registration={register("registration_number")}
                />
                <InputField
                  label="Date"
                  type="date"
                  error={formState.errors["date"]}
                  registration={register("date")}
                />
                <InputField
                  label="Name of Examination Paper"
                  error={formState.errors["name_of_examination_paper"]}
                  registration={register("name_of_examination_paper")}
                />
                <InputField
                  label="Year of Study"
                  error={formState.errors["year_of_study"]}
                  registration={register("year_of_study")}
                />
                <InputField
                  label="Nature of Malpractice"
                  error={formState.errors["nature_of_malpractice"]}
                  registration={register("nature_of_malpractice")}
                />
                <div className="col-span-2">
                  <DocumentUploadField
                    label="Attach Evidence of Malpractice"
                    accept="application/pdf"
                    onChange={(e) => {
                      // set maximum size of file to 10 MB
                      if (e.target.files[0].size > 10000000) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "File size is too large!",
                        });
                        return;
                      }
                      if (e.target.files[0].type !== "application/pdf") {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "File type is not supported!",
                        });
                        return;
                      }
                      setLocation(e.target.files[0]);
                    }}
                  />
                </div>
                <InputField
                  label="Name of the supervisor or reconnoiter"
                  error={formState.errors["name_of_supervisor"]}
                  registration={register("name_of_supervisor")}
                />
                <InputField
                  label="Name of the principal"
                  error={formState.errors["name_of_principal"]}
                  registration={register("name_of_principal")}
                />
                <div className="col-span-2">
                  <DocumentUploadField
                    label="Scanned Signature"
                    accept="application/pdf"
                    onChange={(e) => {
                      // set maximum size of file to 10 MB
                      if (e.target.files[0].size > 10000000) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "File size is too large!",
                        });
                        return;
                      }
                      if (e.target.files[0].type !== "application/pdf") {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "File type is not supported!",
                        });
                        return;
                      }
                      setSignature(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            )}
          </Form>
          <Button
            className="mt-4"
            form="report-malpractice"
            type="submit"
            size="sm"
            isLoading={reportMalpracticeMutation.isLoading}
          >
            Submit Malpractice Report
          </Button>
        </div>
      </div>
    </MainLayout>
  );
};
