import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const createAlumni = ({ data }) => {
  return axios.post(`/alumni`, data);
};

export const useCreateAlumni = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newAlumni) => {
      await queryClient.cancelQueries('alumnis');

      const previousAlumnis = queryClient.getQueryData('alumnis');

      queryClient.setQueryData('alumnis', [...(previousAlumnis || []), newAlumni.data]);

      return { previousAlumnis };
    },
    onError: (_, __, context) => {
      if (context?.previousAlumnis) {
        queryClient.setQueryData('alumnis', context.previousAlumnis);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('alumnis');
      addNotification({
        type: 'success',
        title: 'Successfully applied for UBTEB Alumni',
      });
    },
    ...config,
    mutationFn: createAlumni,
  });
};
