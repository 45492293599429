import { lazyImport } from "utils/lazyImport";
import { NotFound } from "features/landing";

const { NewsRoutes } = lazyImport(() => import("features/news"), "NewsRoutes");
const { PublicationsRoutes } = lazyImport(
  () => import("features/publications"),
  "PublicationsRoutes"
);
const { SearchRoutes } = lazyImport(
  () => import("features/search"),
  "SearchRoutes"
);
const { LegalRoutes } = lazyImport(
  () => import("features/legal"),
  "LegalRoutes"
);
const { InnovationsRoutes } = lazyImport(
  () => import("features/innovations"),
  "InnovationsRoutes"
);
const { SpecialNeedsRoutes } = lazyImport(
  () => import("features/specialNeeds"),
  "SpecialNeedsRoutes"
);
const { PressReleaseRoutes } = lazyImport(
  () => import("features/pressRelease"),
  "PressReleaseRoutes"
);
const { ReportsRoutes } = lazyImport(
  () => import("features/reports"),
  "ReportsRoutes"
);
const { TendersRoutes } = lazyImport(
  () => import("features/tenders"),
  "TendersRoutes"
);
const { CareersRoutes } = lazyImport(
  () => import("features/careers"),
  "CareersRoutes"
);
const { EventsRoutes } = lazyImport(
  () => import("features/events"),
  "EventsRoutes"
);
const { MultimediaRoutes } = lazyImport(
  () => import("features/multimedia"),
  "MultimediaRoutes"
);
const { AboutRoutes } = lazyImport(
  () => import("features/about"),
  "AboutRoutes"
);
const { RegistrationRoutes } = lazyImport(
  () => import("features/registration"),
  "RegistrationRoutes"
);
const { AssessmentRoutes } = lazyImport(
  () => import("features/assessment"),
  "AssessmentRoutes"
);
const { ContactRoutes } = lazyImport(
  () => import("features/contact"),
  "ContactRoutes"
);
const { ActsRoutes } = lazyImport(() => import("features/acts"), "ActsRoutes");

export const publicRoutes = [
  {
    path: "/acts/*",
    element: <ActsRoutes />,
  },
  {
    path: "/innovations/*",
    element: <InnovationsRoutes />,
  },
  {
    path: "/special-needs/*",
    element: <SpecialNeedsRoutes />,
  },
  {
    path: "/news/*",
    element: <NewsRoutes />,
  },
  {
    path: "/search/*",
    element: <SearchRoutes />,
  },
  {
    path: "/multimedia/*",
    element: <MultimediaRoutes />,
  },
  {
    path: "/about-us/*",
    element: <AboutRoutes />,
  },
  {
    path: "/events/*",
    element: <EventsRoutes />,
  },
  {
    path: "/tenders/*",
    element: <TendersRoutes />,
  },
  {
    path: "/press-release/*",
    element: <PressReleaseRoutes />,
  },
  {
    path: "/reports/*",
    element: <ReportsRoutes />,
  },
  {
    path: "/legal/*",
    element: <LegalRoutes />,
  },
  {
    path: "/careers/*",
    element: <CareersRoutes />,
  },
  {
    path: "/publications/*",
    element: <PublicationsRoutes />,
  },
  {
    path: "/assessment/*",
    element: <AssessmentRoutes />,
  },
  {
    path: "/registration/*",
    element: <RegistrationRoutes />,
  },
  {
    path: "/contact-us/*",
    element: <ContactRoutes />,
  },
  { path: "*", element: <NotFound /> },
];
