/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import { Calendar, Circle } from "react-feather";
import { Link } from "react-router-dom";
import { formatDate } from "utils/format";
import { useTimelines } from "../api/getTimeline";

const LinkItem = ({ title, date, to }) => (
  <a
    href={to}
    target="_blank"
    className="flex flex-row items-center text-xs space-x-2 hover:bg-gray-100 p-1"
  >
    <Circle className="h-2 w-2 text-blue-700" />
    <div className="flex flex-col">
      <span className="text-gray-700">{title}</span>
      <div className="flex flex-row space-x-2 items-center">
        <Calendar className="h-4 w-4 text-yellow-300" />
        <span className="text-gray-500">{date}</span>
      </div>
    </div>
  </a>
);

export const Timeline = () => {
  const timelinesQuery = useTimelines();

  if (timelinesQuery.isLoading) {
    return <></>;
  }

  return (
    <div className="shadow-sm hover:shadow-md bg-white rounded-b-lg border border-red-100 rounded-t-lg">
      <div className="border-b bg-red-100 border-red-500 text-red-500 flex flex-row space-x-2 items-center px-4 py-2 rounded-t-lg mb-2  font-extrabold tracking-tight sm:text-xl">
        <div className="flex flex-row space-x-2 text-sm uppercase items-center whitespace-nowrap">
          {/* <DownloadCloud className="h-4 w-4 inline-block" /> */}
          <span>Timeline</span>
        </div>
      </div>
      <div
        className="h-[20vh] md:h-[40vh] flex flex-col space-y-4 rounded-b-lg"
        style={{
          overflowX: "scroll",
        }}
      >
        {timelinesQuery.data.map((timeline) => (
          <LinkItem
            key={timeline._id}
            title={timeline.title}
            date={formatDate(timeline.date)}
            to={timeline.url}
          />
        ))}
        {timelinesQuery.data.length === 0 && <div className="text-center text-gray-400 my-10 md:my-20 italic"> No current timeline</div>}
      </div>
    </div>
  );
};
