import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getCounters = () => {
  return axios.get('/counts');
};

export const useCounters = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['counters'],
    queryFn: () => getCounters(),
  });
};
