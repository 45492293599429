import { Head } from "components/Head";
import { MainLayout } from "components/Layout";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { BannerCarousel } from "../components/BannerCarousel";
import { Counter } from "../components/Counter";
import { DownloadsResources } from "../components/DownloadsResources";
import { LatestNews } from "../components/LatestNews";
import { Partners } from "../components/Partners";
import { RegisterAlumni } from "../components/RegisterAlumni";
import { Timeline } from "../components/Timeline";
import { UBTEBLinks } from "../components/UBTEBLinks";

export const Landing = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout title="Home">
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="ubtebCookieConsent"
        style={{ backgroundColor: "rgba(0,0,0,0.8)" }}
        buttonStyle={{ color: "#4e503b", fontSize: "12px" }}
        expires={150}
      >
        We use cookies and other identifiers to help improve your online
        experience. By using our website you are agreeing to this. Read our{" "}
        <Link to="/privacy-policy"> privacy policy</Link> to find out what
        cookies are used for and how to change your settings.
      </CookieConsent>
      <Head description="Home" />

      <section id="banner">
        <div className="mx-auto flex flex-col gap-4 px-4 md:px-8 mt-0 md:mt-5 space-y-12 md:space-y-0 md:flex-row">
          <div className="flex space-y-12 w-full md:w-2/3">
            <BannerCarousel />
          </div>
          <div className="flex space-y-12 w-full md:w-1/3">
            <LatestNews />
          </div>
        </div>
      </section>
      {/* <div className="container m-4 mx-2 md:mx-20 grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
        
      </div> */}
      <Counter />
      <div className="mx-2 md:mx-10 grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="md:col-span-2">
          <UBTEBLinks />
        </div>
        <DownloadsResources />
        <Timeline />
      </div>
      <RegisterAlumni />
      {/* <div className="mx-2 md:mx-20 grid grid-cols-1 md:grid-cols-3 gap-4 my-10">
        <WhatsNew />
        <OutlookMagazine />
        <KeyTimes />
      </div> */}
      <Partners />
    </MainLayout>
  );
};
