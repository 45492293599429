import { UPLOADS_URL } from "config";
import React from "react";
import { usePartners } from "../api/getPartners";

export const Partners = () => {
  const partnersQuery = usePartners();

  if (partnersQuery.isLoading) {
    return <></>;
  }

  if (!partnersQuery.data) return null;

  return (
    <div className="mx-2 md:mx-32">
      <div className="flex flex-col space-y-2">
        <span className="text-blue-800  font-extrabold tracking-tight sm:text-xl">
          Our Partners
        </span>
        <div className="w-12 h-2 bg-blue-800"></div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-24 mb-24">
        {partnersQuery.data.map((partner, index) => (
          <a
            href={partner.website}
            target="_blank"
            key={index}
            rel="noreferrer noopener"
            className="flex flex-col items-center"
          >
            <img
              src={UPLOADS_URL + partner.logo}
              alt={partner.name}
              className="w-32 h-32 object-contain grayscale hover:grayscale-0 hover:scale-105 transition-all duration-300"
            />
          </a>
        ))}
      </div>
    </div>
  );
};
