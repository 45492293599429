import React from "react";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";

export const RegisterAlumni = () => {
  return (
    <div className="bg-[url('assets/images/backgrounds/board-room.png')]  bg-opacity-25 bg-auto bg-center my-14 bg-no-repeat bg-cover">
      <div className="px-12 md:px-24 py-20 text-white font-black bg-black bg-opacity-50">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col space-y-3">
            <div className="font-bold text-2xl">UBTEB Alumni</div>
            <div className="my-1 text-md">
              The UBTEB Alumni wants to connect you with groups that speak to
              who you are, what you care about, and what matters to you most.
              Get started with those listed below—and if you have ideas for
              other groups around shared interests, click here for the "Criteria
              for Clubs, Groups and Area Representatives."
            </div>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="font-black text-2xl">Get Involved</div>
            <div className="my-1 text-md">
              Learn more about joining or starting a group based on shared
              interests and affinities.
            </div>
            <div className="my-1">
              <Link
                to="/alumni-registration"
                className="flex flex-row space-x-2 w-40 items-center bg-red-600 p-3 px-4 rounded-md"
              >
                <span>Register Today</span>
                <ChevronRight className="h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
