import { Button, Spinner } from "components/Elements";
import { Notifications } from "components/Notifications/Notifications";
import { queryClient } from "lib/react-query";
import * as React from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { AiFillThunderbolt } from "react-icons/ai";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

const ErrorFallback = () => {
  return (
    <div
      className="text-gray-400 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <AiFillThunderbolt className="text-orange-600 h-6 w-6" />
      <h2 className="text-sm font-semibold">Sorry, something went wrong </h2>
      <Button
        className="mt-4 text-sm "
        variant="inverse"
        onClick={() => {
          localStorage.clear();
          window.location.assign(window.location.origin);
        }}
      >
        Try again
      </Button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  useEffect(() => {
    const getTawkInfo = ({ src }) => {
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = src;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    };
    getTawkInfo("https://embed.tawk.to/63b69f70c2f1ac1e202bd10f/1gm0ltkoh");
  }, []);
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
            <Notifications />
            <Router>{children}</Router>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
