import { Head } from "components/Head";
import { Breadcrumbs, MainLayout } from "components/Layout";
import {
  ArrowRightCircle,
  ChevronRight,
  Clock,
  Home,
  MapPin,
  Phone,
  Send,
} from "react-feather";
import { Link } from "react-router-dom";
import { Button, Card } from "components/Elements";
import {
  CheckboxField,
  DocumentUploadField,
  Form,
  InputField,
  SelectField,
} from "components/Form";
import Swal from "sweetalert2";
import { useState } from "react";
import { useRequestAcademicTranscripts } from "../api/requestAcademicTranscripts";
import { useEffect } from "react";
import newsBgImg from "assets/images/banner/skills.jpeg";

export const AcademicTranscripts = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [location, setLocation] = useState("");

  const transcriptsMutation = useRequestAcademicTranscripts();

  const routes = [
    {
      name: "Academic Transcripts & Certificates",
      path: "/academic-transcripts-&-certificates",
    },
  ];

  if (transcriptsMutation.isLoading) return <></>;

  return (
    <MainLayout title="Certify Academic Documents" className="bg-gray-100">
      <Head description="Certify Academic Documents" />
      <div class="relative overflow-hidden h-80">
        <img
          src={newsBgImg}
          alt="Academic Transcripts & Certificates"
          class="object-cover w-full h-full"
        />
        <div class="absolute w-full py-10 bottom-0 inset-x-0  text-white px-24 text-xs leading-4 bg-gradient-to-t from-black">
          <h1 className="text-6xl font-bold">
            Academic Transcripts & Certificates
          </h1>
          <Breadcrumbs routes={routes} />
        </div>
      </div>
      <div className="mx-10 md:mx-24 my-10">
        <div className="bg-red-100 text-red-600 rounded-md p-8 shadow">
          <p>
            This form must only be filled by the UBTEB Head of Center or his/her
            Authorised Officer.
          </p>
        </div>

        <div className="my-4">
          <span className="text-lg font-bold">
            Application form to Collect Academic Transcripts & Certificates
          </span>
          <br />
          <span className="text-sm"></span>
        </div>
        <div className="">
          <Form
            id="report-malpractice"
            onSubmit={async (values) => {
              const {
                name_of_candidate,
                registration_number,
                date,
                name_of_examination_paper,
                year_of_study,
                nature_of_malpractice,
                name_of_supervisor,
                name_of_principal,
              } = values;
              const bodyFormData = new FormData();
              bodyFormData.append("evidence_of_malpractice", location);
              bodyFormData.append("name_of_candidate", name_of_candidate);
              bodyFormData.append("registration_number", registration_number);
              bodyFormData.append("date", date);
              bodyFormData.append(
                "name_of_examination_paper",
                name_of_examination_paper
              );
              bodyFormData.append("year_of_study", year_of_study);
              bodyFormData.append(
                "nature_of_malpractice",
                nature_of_malpractice
              );
              bodyFormData.append("name_of_supervisor", name_of_supervisor);
              bodyFormData.append("name_of_principal", name_of_principal);
              await transcriptsMutation.mutateAsync({
                data: bodyFormData,
              });
            }}
          >
            {({ register, formState }) => (
              <>
                <div className="border border-blue-800 rounded-md shadow bg-white p-10 mb-4">
                  <div className="font-bold text-lg mb-4">
                    1. Examination Centre Details
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputField
                      label="Examination Centre Name"
                      error={formState.errors["examination_centre_name"]}
                      registration={register("examination_centre_name")}
                    />
                    <InputField
                      label="Examination Centre No(s)"
                      error={formState.errors["examination_centre_no"]}
                      registration={register("examination_centre_no")}
                    />
                    <InputField
                      label="Contact Email"
                      error={formState.errors["contact_email"]}
                      registration={register("contact_email")}
                    />
                    <InputField
                      label="Contact Officer"
                      error={formState.errors["contact_officer"]}
                      registration={register("contact_officer")}
                    />
                    <InputField
                      label="Contact Officer Phone"
                      error={formState.errors["contact_officer_telephone_no"]}
                      registration={register("contact_officer_telephone_no")}
                    />
                  </div>
                </div>
                <div className="border border-blue-800 rounded-md shadow bg-white p-10 mb-4">
                  <div className="font-bold text-lg mb-4">
                    2. Details of Academic Documents to be Collected
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <SelectField
                      label="Type of Document"
                      error={formState.errors["type_of_document"]}
                      registration={register("type_of_document")}
                      options={[
                        { value: "Transcript", label: "Transcript" },
                        { value: "Certificate", label: "Certificate" },
                      ]}
                    />
                    <InputField
                      label="Program Codes"
                      error={formState.errors["program_codes"]}
                      registration={register("program_codes")}
                    />
                    <InputField
                      label="Cohort (eg, August 2017 Entry)"
                      error={formState.errors["cohort"]}
                      registration={register("cohort")}
                    />
                    <InputField
                      label="Date of Appointment Requested"
                      type="date"
                      error={formState.errors["date_of_appointment_requested"]}
                      registration={register("date_of_appointment_requested")}
                    />
                    <InputField
                      label="Name of Head of Center"
                      error={formState.errors["name_of_head_of_center"]}
                      registration={register("name_of_head_of_center")}
                    />
                    <div className="col-span-2 bg-gray-100 space-y-4 border border-gray-300 p-10">
                      <div className="font-bold text-lg">Consent</div>
                      <CheckboxField
                        label="I give my consent for UBTEB to provide my examinations center Transcripts, Certificates and other related documents to the bearer of this form "
                        error={formState.errors["consent"]}
                        registration={register("consent")}
                      />
                      <DocumentUploadField
                        label="Insert Digital Authorised Signature & Date"
                        accept="application/pdf"
                        onChange={(e) => {
                          // set maximum size of file to 10 MB
                          if (e.target.files[0].size > 10000000) {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "File size is too large!",
                            });
                            return;
                          }
                          if (e.target.files[0].type !== "application/pdf") {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "File type is not supported!",
                            });
                            return;
                          }
                          setLocation(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="border border-blue-800 rounded-md shadow bg-white p-10 mb-4">
                  <div className="font-bold text-lg mb-4">
                    3. Confirmation of Appointment by UBTEB Officials
                  </div>
                  <div className="py-2">
                    You will be notified through your Examination Centre Contact
                    Email once the UBTEB official has confirmed.{" "}
                  </div>
                </div>
              </>
            )}
          </Form>
          <div className="flex justify-end">
            <Button
              className="mt-4"
              form="report-malpractice"
              type="submit"
              size="sm"
              isLoading={transcriptsMutation.isLoading}
            >
              Submit Request
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
