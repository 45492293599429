import { Card, Link } from "components/Elements";
import { Head } from "components/Head";
import { Breadcrumbs, MainLayout } from "components/Layout";
import { useEffect } from "react";
import { usePosts } from "../api/getPosts";
import newsBgImg from "assets/images/banner/skills.jpeg";
import { ArrowRightCircle } from "react-feather";

export const ExaminationResults = () => {
  const postsQuery = usePosts();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (postsQuery.isLoading) {
    return <></>;
  }
  const routes = [
    {
      name: "Examination Results",
      path: "/examination-results",
    },
  ];

  if (!postsQuery.data) return null;

  return (
    <MainLayout title="Examination Results">
      <Head title="Examination Results" />
      <div class="relative overflow-hidden h-80">
        <img
          src={newsBgImg}
          alt="Examination Results"
          class="object-cover w-full h-full"
        />
        <div class="absolute w-full py-10 bottom-0 inset-x-0  text-white px-24 text-xs leading-4 bg-gradient-to-t from-black">
          <h1 className="text-6xl font-bold">Examination Results</h1>
          <Breadcrumbs routes={routes} />
        </div>
      </div>
      <div className="mx-20 my-20">
        <Card title="Performance Reports">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4"></div>
        </Card>
        <br />
        <Card title="Speeches">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4"></div>
        </Card>
      </div>
    </MainLayout>
  );
};
