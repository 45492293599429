import { Head } from "components/Head";
import { MainLayout } from "components/Layout";
import {
  ArrowRightCircle,
  ChevronRight,
  Clock,
  Home,
  MapPin,
  Phone,
  Send,
} from "react-feather";
import { Link } from "react-router-dom";
import { Card } from "components/Elements";
import { useEffect } from "react";

export const Payment = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout title="Payment">
      <Head description="Payment" />
      <div className="bg-[url('assets/images/backgrounds/mandate.png')]  bg-opacity-25 bg-auto bg-center">
        <div className="px-12 md:px-24 uppercase py-20 text-white font-black text-2xl bg-black bg-opacity-50">
          <span>Make a Payment</span>
          <nav class="flex mt-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
              <li class="inline-flex items-center">
                <Link
                  to="/"
                  class="inline-flex items-center text-sm font-medium text-gray-400 hover:text-white dark:text-gray-400 dark:hover:text-white"
                >
                  <Home className="w-4 h-4 mr-2" />
                  Home
                </Link>
              </li>
              <li>
                <div class="flex items-center">
                  <ChevronRight className="w-4 h-4 mr-2" />
                  <Link
                    href="/payment"
                    class="inline-flex items-center text-sm font-medium text-gray-400 hover:text-white dark:text-gray-400 dark:hover:text-white"
                  >
                    Payment
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="mx-10 md:mx-24 my-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="bg-blue-100 shadow-md rounded-md p-10 text-blue-700 hover:bg-blue-700 hover:text-white">
            <span>Account Number</span>
            <br />
            <span className="text-2xl font-bold">9030005776596</span>
            <br />
            <span>Stanbic Bank Metro Branch</span>
          </div>
          <div className="bg-red-100 shadow-md rounded-md p-10 text-red-600 hover:bg-red-700 hover:text-white">
            <span>Special Line</span>
            <br />
            <span className="text-2xl font-bold">0393-208059</span>
            <br />
            <span>Sam, Peter and Frank</span>
          </div>
        </div>
        <Card title="Guidance on Payment Mode">
          <ul>
            <li className="border-l-2 border-red-700 mb-3 p-4">
              After approving all candidates online, send the list of candidates
              to UBTEB on email;&nbsp;
              <a
                href="mailto:ubtebreg@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                ubtebreg@gmail.com
              </a>
              .
            </li>
            <li className="border-l-2 border-red-700 mb-3 p-4">
              RETAKERS: As you send the candidates' list please attach a tally
              of retakers with a respective programme of study and papers to
              retake.
            </li>
            <li className="border-l-2 border-red-700 mb-3 p-4">
              On receipt of the above, UBTEB will issue Fees form and list of
              papers registered to enable the Head of the centre to pay in the
              bank on account Number; 9030005776596, Stanbic bank Metro branch.
            </li>
            <li className="border-l-2 border-red-700 mb-3 p-4">
              The accounts department will issue the receipt.
            </li>
          </ul>
        </Card>
        <Card title="Payment Note">
          <p className="mb-3">
            The Money should be deposited and signed off in the Institutions
            name NOT depositors name. For instance, If the Bursar Okello Amos is
            paying for UTC-Lira to UBTEB, let him not put his name BUT put
            UTC-Lira.
          </p>
          <p className="mb-3">
            For inquiries on registration and payment modalities, call special
            line 0393-208059 and talk to Sam, Peter and Frank. Keeping you
            posted.
          </p>
        </Card>
      </div>
    </MainLayout>
  );
};
