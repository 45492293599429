import React from "react";

export const Card = ({ title, children }) => {
  return (
    <>
      <div className="shadow rounded-md p-4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-101 duration-300">
        {title && (
          <>
            <div className="p-2 text-blue-800 rounded-t-md font-extrabold uppercase tracking-tight sm:text-xl">
              {title}
            </div>
            <div className="h-1 w-40 bg-blue-800 rounded-b-md"></div>
          </>
        )}
        <div className="bg-white p-2 md:p-6 text-black">{children}</div>
      </div>
    </>
  );
};
