import React from "react";

export const UGFlag = () => {
  return (
    <div className="flex flex-col">
      <div className="w-100 h-2 bg-black"></div>
      <div className="w-100 h-2 bg-yellow-300"></div>
      <div className="w-100 h-2 bg-red-600"></div>
    </div>
  );
};
