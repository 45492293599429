import {
  AcademicTranscripts,
  BecomeAnAlumni,
  CertifyAcademicDocuments,
  EmisManuals,
  EquateDocumentQualifications,
  ExaminationResults,
  Landing,
  Payment,
  ReplaceLostDocuments,
  ReportMalpractice,
} from "features/landing";
import { Search } from "features/search/routes/Search";

import { useRoutes } from "react-router-dom";

import { publicRoutes } from "./public";

export const AppRoutes = () => {
  const commonRoutes = [
    { path: "/", element: <Landing /> },
    { path: "/emis-manuals", element: <EmisManuals /> },
    { path: "/payment", element: <Payment /> },
    { path: "/report-malpractice", element: <ReportMalpractice /> },
    { path: "/examination-results", element: <ExaminationResults /> },
    { path: "/alumni-registration", element: <BecomeAnAlumni /> },
    {
      path: "/academic-transcripts-&-certificates",
      element: <AcademicTranscripts />,
    },
    {
      path: "/replace-lost-academic-documents",
      element: <ReplaceLostDocuments />,
    },
    {
      path: "/equate-document-qualifications",
      element: <EquateDocumentQualifications />,
    },
    {
      path: "/certify-academic-documents",
      element: <CertifyAcademicDocuments />,
    },

    {
      path: "/search",
      element: <Search />,
    },
  ];

  const element = useRoutes([...publicRoutes, ...commonRoutes]);

  return <>{element}</>;
};
