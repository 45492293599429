import React, { useState } from "react";
import { Facebook, Linkedin, Twitter } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/logo.png";
import { usePreferences } from "features/settings/getPreferences";
import { SearchIcon } from "@heroicons/react/outline";

export const TopBar = () => {
  const preferenceQuery = usePreferences();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") return;
    navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
    setSearchQuery("");
  };
  if (preferenceQuery.isLoading) {
    return <></>;
  }
  if (!preferenceQuery.data) return null;

  return (
    <nav className="bg-white mx-auto hidden md:block">
      <div className="flex flex-wrap sm:justify-center md:justify-between items-center px-8 mx-auto max-w-screen-xl py-0.5">
        <div className="flex justify-center justify-between w-full items-center">
          <Link to="/" className="flex items-center">
            <img src={logo} className="w-20" alt="UBTEB Logo" />
            <span className="self-center text-xs md:text-md font-semibold wrap-text md:whitespace-nowrap text-blue-800 dark:text-white px-2">
              <span className="uppercase font-black">
                Uganda Business and Technical Examinations Board
              </span>
              <br />
              <span className="text-xs font-normal text-red-500">
                Setting Pace for Quality Assessment
              </span>
            </span>
          </Link>
          <div className="mt-2 md:mt-0 flex flex-col md:flex-row mr-2 space-y-2 items-center space-x-2">
            <form onSubmit={handleSearch}>
              <div className="flex flex-row items-center justify-center bg-blue-100 px-4 w-80 py-2 rounded-lg">
                <input
                  type="text"
                  placeholder="Search for anything..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="border-none focus:outline-none bg-transparent focus:ring-0"
                />
                <button type="submit">
                  <SearchIcon className="h-5 w-5 text-gray-400" />
                </button>
              </div>
            </form>
            <a
              href={`tel:+${preferenceQuery.data?.website?.phone}`}
              className="mr-6 text-sm font-medium text-gray-500 dark:text-white hover:underline"
            >
              +{preferenceQuery.data?.website?.phone}
            </a>
            <div className="flex flex-row items-center space-x-2">
              <a
                href={`https://facebook.com/${preferenceQuery.data?.website?.facebook}`}
                target="_blank"
                rel="noreferrer"
                className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline"
              >
                <Facebook className="text-gray-700 h-4 w-4" />
              </a>
              <a
                href={`https://twitter.com/${preferenceQuery.data?.website?.twitter}`}
                target="_blank"
                rel="noreferrer"
                className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline"
              >
                <Twitter className="text-gray-700 h-4 w-4" />
              </a>
              <a
                href={`https://linkedin.com/in/${preferenceQuery.data?.website?.linkedIn}`}
                target="_blank"
                rel="noreferrer"
                className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline"
              >
                <Linkedin className="text-gray-700 h-4 w-4" />
              </a>
              <a
                href={preferenceQuery.data?.website?.web_mail}
                target="_blank"
                rel="noreferrer"
                className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline"
              >
                <span className="text-gray-700">Web Mail</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
