import React from "react";
import CoatOfArmsFlag from "assets/coat_of_arms.png";
import { FollowUs } from "./FollowUs";
import { UGFlag } from "./UGFlag";
import { Link } from "react-router-dom";
import { usePreferences } from "features/settings/getPreferences";
import { Linkedin } from "react-feather";

export const Footer = () => {
  const preferenceQuery = usePreferences();

  if (preferenceQuery.isLoading) {
    return <></>;
  }
  if (!preferenceQuery.data) return null;
  return (
    <>
      {/* <Newsletter /> */}

      <FollowUs />

      <UGFlag />
      <footer className="bg-gray-50 dark:bg-gray-800">
        <div className="p-4 py-6 mx-auto max-w-screen-xl md:p-8 lg:p-10">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-4 lg:grid-cols-4">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                About Us
              </h2>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/about-us/how-we-work" className=" hover:underline">
                    How we work
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/about-us/how-we-are-run"
                    className="hover:underline"
                  >
                    How we are run
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/about-us/mission" className="hover:underline">
                    Our Values
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/about-us/our-history" className="hover:underline">
                    Our History
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/about-us/mission" className="hover:underline">
                    Our Mission & Values
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/about-us/our-leadership"
                    className="hover:underline"
                  >
                    Management Team / Board
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Other Important Links
              </h2>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <a
                    href="https://emis.go.ug/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    EMIS
                  </a>
                </li>
                <li className="mb-4">
                  <Link to="/careers" className="hover:underline">
                    Careers
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/tenders" className="hover:underline">
                    Tenders
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Research
              </h2>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <a
                    href="https://iaea.info/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    International Association for Educational Assessment
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://www.aeaafrica.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    Association for Educational Assessment in Africa (AEAA)
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://iveta.global/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    International Vocational Education and Training Association
                    (IVETA)
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Contact Us
              </h2>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  Plot 7, Valley Drive, <br /> Ntinda P.O Box 1499, <br />{" "}
                  Kampala-Uganda
                  <br />
                  <br />
                  Email: info@ubteb.go.ug/esubteb@gmail.com
                  <br />
                  Alternate Email: ubtebcommunications@gmail.com
                  <br />
                  Tel: 0392 002468/+256 414 289786/+256 414289787
                </li>
              </ul>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="text-center">
            <div className="flex justify-center items-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
              <img
                src={CoatOfArmsFlag}
                alt="Coat of Arms"
                className="w-10 h-10"
              />
            </div>
            <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
              © 2023{" "}
              UBTEB
              . All Rights Reserved.
            </span>
            <ul className="flex justify-center mt-5 space-x-5">
              <li>
                <a
                  href={`https://facebook.com/${preferenceQuery.data?.website?.facebook}`}
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href={`https://linkedin.com/in/${preferenceQuery.data?.website?.linkedIn}`}
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                  <Linkedin className="w-5 h-5" />
                </a>
              </li>
              <li>
                <a
                  href={`https://twitter.com/${preferenceQuery.data?.website?.twitter}`}
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};
