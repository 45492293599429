import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";

export const requestAcademicTranscripts = ({ data }) => {
  return axios.post(`/academic-transcripts`, data);
};

export const useRequestAcademicTranscripts = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newAlumni) => {
      await queryClient.cancelQueries("requests");

      const previousRequests = queryClient.getQueryData("requests");

      queryClient.setQueryData("requests", [
        ...(previousRequests || []),
        newAlumni.data,
      ]);

      return { previousRequests };
    },
    onError: (_, __, context) => {
      if (context?.previousRequests) {
        queryClient.setQueryData("requests", context.previousRequests);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("requests");
      addNotification({
        type: "success",
        title: "Thank you. We will be in touch within 3 working days.",
      });
    },
    ...config,
    mutationFn: requestAcademicTranscripts,
  });
};
