import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UbtebLink } from "./UbtebLink";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const NavLinks = () => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {UbtebLink.map((link, index) => (
        <div key={index}>
          <div className="px-3 md:px-0 text-left md:cursor-pointer group">
            <h1
              className="py-4 flex justify-between items-center md:pr-0 pr-5 group"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.name}
              <span className="text-xl md:hidden inline">
                {!heading === link.name ? <BiChevronUp /> : <BiChevronDown />}
              </span>
              <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                {!heading === link.name ? <BiChevronUp /> : <BiChevronDown />}
              </span>
            </h1>
            {link.submenu && (
              <div>
                <div className="absolute top-12 hidden group-hover:md:block hover:md:block">
                  <div className="py-2">
                    <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 bg-sky-50 rotate-45"
                    ></div>
                  </div>
                  <div className="bg-sky-50 text-black capitalize p-5 grid grid-cols-2 gap-6">
                    {link.sublinks.map((mysublinks, index) => (
                      <div key={index}>
                        <h1 className="text-lg font-semibold">
                          {mysublinks.Head}
                        </h1>
                        {mysublinks.sublink.map((slink, index) => (
                          <li
                            key={index}
                            className="text-sm text-white-600 my-2.5"
                          >
                            <Link
                              to={slink.link}
                              className="hover:text-primary"
                            >
                              {slink.name}
                            </Link>
                          </li>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublinks.map((slinks, index) => (
              <div key={index}>
                {slinks.Head ? (
                  <div>
                    <h1
                      onClick={() =>
                        subHeading !== slinks.Head
                          ? setSubHeading(slinks.Head)
                          : setSubHeading("")
                      }
                      className="py-4 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center md:pr-0 pr-5"
                    >
                      {slinks.Head}

                      <span className="text-xl md:mt-1 md:ml-2 px-4 inline">
                        {!heading === link.name ? (
                          <BiChevronUp />
                        ) : (
                          <BiChevronDown />
                        )}
                      </span>
                    </h1>
                    <div
                      className={`${
                        subHeading === slinks.Head ? "md:hidden" : "hidden"
                      }`}
                    >
                      {slinks.sublink.map((slink, index) => (
                        <li key={index} className="py-3 pl-14">
                          <Link to={slink.link}>{slink.name}</Link>
                        </li>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    {slinks.sublink.map((slink, index) => (
                      <li key={index} className="py-3 pl-7">
                        <Link to={slink.link}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
