import React from "react";
import { useBanners } from "../api/getBanners";
import { Carousel } from "components/Elements";
import { UPLOADS_URL } from "config";

export const BannerCarousel = () => {
  const bannersQuery = useBanners();

  if (bannersQuery.isLoading) {
    return <></>;
  }

  if (!bannersQuery.data) return null;

  // Map the banners with draft false to the Carousel component
  const images = bannersQuery.data
    .filter((banner) => !banner.draft)
    .map((banner) => ({
      src: `${UPLOADS_URL}${banner.image}`,
      alt: banner.title,
    }));

  return <Carousel images={images} />;
};
