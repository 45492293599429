/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import { Link2 } from "react-feather";
import { Link } from "react-router-dom";
import UBTEB10Logo from "assets/images/links/ubteb.png";
import { useLinks } from "../api/getLinks";

const LinkItem = ({ title, to }) => (
  <a
    href={to}
    target="_blank"
    rel="noreferrer"
    className="flex flex-row text-xs justify-start space-x-2 hover:text-red-500 hover:underline"
  >
    <Link2 className="h-4 w-4" />
    <span>{title}</span>
  </a>
);

export const UBTEBLinks = () => {
  const linksQuery = useLinks();

  if (linksQuery.isLoading) {
    return <></>;
  }

  if (!linksQuery.data) return null;

  return (
    <div className="shadow-sm hover:shadow-md bg-white rounded-b-lg border border-red-100 rounded-t-lg">
      <div className="border-b bg-red-100 border-red-500 text-red-500 flex flex-row items-center justify-between  px-4 py-2 rounded-t-lg mb-2  font-extrabold tracking-tight sm:text-xl">
        <div className="flex flex-row space-x-2 text-sm justify-center text-center items-center">
          {/* <Link2 className="h-4 w-4 inline-block" /> */}
          <span>UBTEB LINKS</span>
        </div>
        {/* <button className="bg-white rounded-full hover:text-red-500 hover:underline p-1 px-4 text-blue-700 text-xs  items-center justify-center flex">
          View Archives
        </button> */}
      </div>
      <div className="mx-1 grid grid-cols-1 md:grid-cols-4 gap-1 items-start">
        <div className="col-span-2">
          <marquee
            direction="up"
            height="100%"
            scrollamount="3"
            behavior="scroll"
            scrolldelay="0"
            onFinished={(e) => {
              // continue scrolling
              e.currentTarget.start();
            }}
            onMouseOver={(e) => {
              e.currentTarget.stop();
            }}
            onMouseOut={(e) => {
              e.currentTarget.start();
            }}
          >
            <div className="h-[20vh] md:h-[60vh] p-1 flex flex-col space-y-1 rounded-b-lg">
              <div className="p-4 flex flex-col space-y-4 rounded-b-lg h-100">
                {linksQuery.data
                  .filter((link) => !link.draft)
                  .slice(0, 5)
                  .map((link, index) => (
                    <LinkItem key={index} to={link.url} title={link.title} />
                  ))}
              </div>
            </div>
          </marquee>
        </div>
        <div className="col-span-2">
          <div className=" p-2 grid grid-cols-1 gap-2 mb-2">
            <div className="flex flex-row space-x-2 items-center">
              <img
                src={UBTEB10Logo}
                alt="UBTEB10 Logo"
                className="h-10 w-10 md:ml-2"
              />
              <div className="col-span-2 flex flex-col">
                <span className="font-black text-md text-blue-800">EMIS</span>
                <span className="text-gray-400 text-xs">
                  Access your EMIS Account Online
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-1 ml-14 text-xs">
              <a
                href="http://emis.ubteb.go.ug:8080/"
                rel="noreferrer"
                target="_blank"
                className="text-red-500 hover:underline"
              >
                Go to Portal
              </a>
              <Link to="/payment" className="text-red-500 hover:underline">
                Make a payment
              </Link>
              <Link to="/emis-manuals" className="text-red-500 hover:underline">
                Manuals for EMIS
              </Link>
            </div>
          </div>

          <div className=" p-2 grid grid-cols-1 gap-2 mb-2">
            <div className="flex flex-row space-x-2 items-center">
              <img
                src={UBTEB10Logo}
                alt="UBTEB10 Logo"
                className="h-10 w-10 md:ml-2"
              />
              <div className="col-span-2 flex flex-col">
                <span className="font-black text-md text-blue-800">
                  CENTRES
                </span>
                <span className="text-gray-400 text-xs">
                  Checkout our accredited centres
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-1 text-xs ml-14">
              <Link
                to="/assessment/accredited-centres/lists"
                className="text-red-500 hover:underline"
              >
                List of Accredited Centres
              </Link>
              <Link
                to="/assessment/accredited-centres/apply-for-centre-number"
                className="text-red-500 hover:underline"
              >
                Apply for Centre number
              </Link>
            </div>
          </div>

          <div className=" p-2 grid grid-cols-1 gap-2 mb-2">
            <div className="flex flex-row space-x-2 items-center">
              <img
                src={UBTEB10Logo}
                alt="UBTEB10 Logo"
                className="h-10 w-10 md:ml-2"
              />
              <div className="col-span-2 flex flex-col">
                <span className="font-black text-md text-blue-800">
                  ACADEMICS
                </span>
                <span className="text-gray-400 text-xs">
                  Manage your academic documents.
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-1 ml-14 text-xs">
              <Link
                to="/report-malpractice"
                className="text-red-500 hover:underline"
              >
                Report Malpractice
              </Link>
              <Link
                to="/certify-academic-documents"
                className="text-red-500 hover:underline"
              >
                Certify Academic Documents
              </Link>
              <Link
                to="/replace-lost-academic-documents"
                className="text-red-500 hover:underline"
              >
                Replace lost academic documents
              </Link>
              <Link
                to="/equate-document-qualifications"
                className="text-red-500 hover:underline"
              >
                Equate document qualifications
              </Link>
              <Link
                to="/academic-transcripts-&-certificates"
                className="text-red-500 hover:underline"
              >
                Academic transcripts & certificates
              </Link>
              <Link
                to="/examination-results"
                className="text-red-500 hover:underline"
              >
                Examination Results
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
