/* eslint-disable jsx-a11y/no-distracting-elements */
import { UPLOADS_URL } from "config";
import React from "react";
import { DownloadCloud, Link2 } from "react-feather";
import { Link } from "react-router-dom";
import { useResources } from "../api/getResources";

const LinkItem = ({ title, to }) => (
  <a
    target="_blank"
    href={to}
    rel="noreferrer"
    className="flex flex-row justify-start space-x-2 hover:underline hover:text-red-500"
  >
    <Link2 className="h-4 w-4" />
    <div className="w-60">{title}</div>
  </a>
);

export const DownloadsResources = () => {
  const resourcesQuery = useResources();

  if (resourcesQuery.isLoading) {
    return <></>;
  }

  if (!resourcesQuery.data) return null;

  return (
    <div className="shadow-sm hover:shadow-md bg-white rounded-b-lg border border-red-100 rounded-t-lg">
      <div className="border-b bg-red-100 border-red-500 text-red-500 flex flex-row space-x-2 items-center px-4 py-2 rounded-t-lg mb-2  font-extrabold tracking-tight sm:text-xl">
        <div className="flex flex-row space-x-2 text-sm uppercase items-center whitespace-nowrap">
          {/* <DownloadCloud className="h-4 w-4 inline-block" /> */}
          <span>Downloads & Resources</span>
        </div>
      </div>
      <div
        className="h-[20vh] md:h-[60vh] p-4 flex flex-col text-xs space-y-4 rounded-b-lg"
        style={{
          overflowX: "scroll",
          scrollBehavior: "smooth",
        }}
      >
        {resourcesQuery.data
          .filter((resource) => !resource.draft)
          .map((resource) => {
            // if resource is a link
            if (resource.url) {
              return (
                <LinkItem
                  key={resource.id}
                  title={resource.title}
                  to={resource.url}
                />
              );
            }

            if (resource.file) {
              return (
                <LinkItem
                  key={resource.id}
                  title={resource.title}
                  to={UPLOADS_URL + resource.file}
                />
              );
            }
          })}
      </div>
    </div>
  );
};
