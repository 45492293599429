import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { TopBar } from "./components/TopBar";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { usePreferences } from "features/settings/getPreferences";

export const MainLayout = ({ children, className }) => {
  const preferenceQuery = usePreferences();

  if (preferenceQuery.isLoading) {
    return <></>;
  }
  if (!preferenceQuery.data) return null;
  return (
    <>
      <TawkMessengerReact
        propertyId={preferenceQuery.data?.tawk_property_id}
        widgetId={preferenceQuery.data?.tawk_widget_id}
      />
      <header className="sticky top-0 z-50">
        <TopBar />
        <NavBar />
      </header>
      <main className={clsx("", className)}>
        {children}
        <Footer />
      </main>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
