import React from "react";
import { useCounters } from "../api/getCounters";

const CounterItem = ({ title, caption }) => (
  <div className="">
    <div>
      <div className="text-center">
        <span className="font-black text-4xl text-red-500">{title}</span>
      </div>
      <div className="text-center">
        <span className="text-gray-800">{caption}</span>
      </div>
    </div>
  </div>
);
export const Counter = () => {
  const countersQuery = useCounters();

  if (countersQuery.isLoading) {
    return <></>;
  }

  if (!countersQuery.data) return null;

  return (
    <div class="px-6 md:px-8 py-12 mr-0">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        {countersQuery.data
          ?.filter((counter) => !counter.draft)
          .slice(0, 4)
          .map((counter, index) => (
            <CounterItem
              key={index}
              title={counter.title}
              caption={counter.period}
            />
          ))}
      </div>
    </div>
  );
};
