import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getPartners = () => {
  return axios.get('/partners');
};

export const usePartners = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['partners'],
    queryFn: () => getPartners(),
  });
};
