export const UbtebLink = [
    {
      name: "About",
      submenu: true,
      sublinks: [
        {
          sublink: [
            { name: "Our Mandate, Mission & Vision", link: "/about-us/mission" },
            { name: "Our Profile", link: "/about-us/our-profile" },
            { name: "Our Leadership", link: "/about-us/our-leadership" },
          ],
        }
      ],
    },
    {
      name: "Registration",
      submenu: true,
      sublinks: [
        {
            sublink: [
                { name: "Circulars", link: "/registration/circulars" },
                { name: "Accreditation", link: "/registration/accreditation" },
                { name: "Summary Sheets", link: "/registration/summary-sheets" },
                { name: "Registration Guidelines", link: "/registration/registration-guide" },
                { name: "Other Documents", link: "/registration/other-documents" },
            ],
        }
      ],
    },
    {
        name: "Assessment",
        submenu: true,
        sublinks: [
          {
            sublink: [
                { name: "Timetables", link: "/assessment/timetables" },
                { name: "Assessment Plan", link: "/assessment/assessment-plan" },
                { name: "Accredited Centres", link: "/assessment/accredited-centres" },
                { name: "Examined Programmes", link: "/assessment/examined-programmes" },
                { name: "World of Work Engagements", link: "/assessment/world-of-work-engagements" },
                { name: "Modular Assessment", link: "/assessment/modular-assessment" },
                { name: "Industrial training", link: "/assessment/industrial-training" },
            ],
          }
        ],
    },
    {
        name: "Media",
        submenu: true,
        sublinks: [
          {
            Head: "Latest News",
            sublink: [
              { name: "Articles", link: "/news" },
              { name: "Multimedia", link: "/multimedia" },
              { name: "Press Release", link: "/press-release" },
              { name: "Upcoming Events", link: "/events" },
            ],
          },
          {
            Head: "Reports",
            sublink: [
              { name: "Annual Reports", link: "/reports/annual" },
              { name: "Activity Reports", link: "/reports/activity" },
            ],
          },
          {
            Head: "Legal",
            sublink: [
              { name: "All Acts", link: "/" },
              { name: "Quality Statement", link: "/" },
            ],
          },
    
          {
            Head: "Resource Centre",
            sublink: [
              { name: "Publications", link: "/publications" },
            ],
          },
        ],
    },
  ];
  