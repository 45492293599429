import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import { AiOutlineMenu,AiOutlineClose } from 'react-icons/ai';
import Logo from "assets/logo.png";

export const NavBar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="md:bg-blue-800 navbar bg-white md:text-white relative md:flex md:items-center md:justify-center">
      <div className="container flex items-center font-medium w-full">
        <div className="z-50 p-2 px-5 md:w-auto w-full flex items-center justify-between">
          <img src={Logo} alt="UBTEB-Logo" className="cursor-pointer w-14 block md:hidden" />
          <div className="text-3xl block md:hidden" onClick={() => setOpen(!open)}>
            {!open 
              ? <AiOutlineMenu/> 
              : <AiOutlineClose/>
            }
          </div>
        </div>
        <ul className="md:flex w-full hidden uppercase items-center justify-center gap-8">
          <li>
            <Link to="/" className="py-4 inline-block">
              Home
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link to="/contact-us" className="py-4 inline-block">
              Contact Us
            </Link>
          </li>
        </ul>
        
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <li>
            <Link to="/" className="py-4 px-3 inline-block">
              Home
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link to="/contact-us" className="py-7 px-3 inline-block">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};
