import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getLinks = () => {
  return axios.get('/links');
};

export const useLinks = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['links'],
    queryFn: () => getLinks(),
  });
};
