import { Card, Link } from "components/Elements";
import { Head } from "components/Head";
import { Breadcrumbs, MainLayout } from "components/Layout";
import { useEffect } from "react";
import { usePosts } from "../api/getPosts";
import newsBgImg from "assets/images/banner/skills.jpeg";
import { ArrowRightCircle } from "react-feather";

export const EmisManuals = () => {
  const postsQuery = usePosts();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (postsQuery.isLoading) {
    return <></>;
  }
  const routes = [
    {
      name: "EMIS Manuals",
      path: "/emis-manuals",
    },
  ];

  if (!postsQuery.data) return null;

  return (
    <MainLayout title="EMIS Manuals">
      <Head title="EMIS Manuals" />
      <div class="relative overflow-hidden h-80">
        <img
          src={newsBgImg}
          alt="EMIS Manuals"
          class="object-cover w-full h-full"
        />
        <div class="absolute w-full py-10 bottom-0 inset-x-0  text-white px-24 text-xs leading-4 bg-gradient-to-t from-black">
          <h1 className="text-6xl font-bold">EMIS Manuals</h1>
          <Breadcrumbs routes={routes} />
        </div>
      </div>
      <div className="mx-20 my-20">
       
      </div>
    </MainLayout>
  );
};
